import React from 'react';
import Layout from '../components/layout/Layout';

const Blog = () => (
  <Layout>
    <h1>Halaman Blog</h1>
    <p>Coming Soon</p>
  </Layout>
);

export default Blog;